import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import UsersBarChart from './Components/UsersBarChart';
import UsersPieChart from './Components/UsersPieChart';
import { Subtitle, Title } from '../../components/helpers/chartHelpers';
import UsersTable from './Components/UsersTable';
import * as PropTypes from 'prop-types';
import PurchaseSumCard from './Components/PurchaseSumCard';
import ChurnLineChart from './Components/ChurnLineChart';
import UserBarByMediumCharts from './Components/UserBarByMediumCharts';
import PushLineChart from './Components/PushLineChart';
import TimeShortcuts from 'components/TimeShortcuts';
import { inject, observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import WeatherHintsTable from './Components/WeatherHintsTable';
import ConsentsChart from './Components/ConsentsChart';
import UsersLineChart from './Components/UsersLineChart';
import SurveysViewsChart from './Components/SurveysViewsChart';
import SurveysAnswersTable from './Components/SurveysAnswersTable';
import RegisteredUsersTable from './Components/RegisteredUsersTable';
import UsersLineChartSumCard from './Components/UsersLineChartSumCard';

function UsersPage({ usersStore: { fetchActions } }) {
  const [shortcutFrom, setShortcutFrom] = useState(null);
  const [shortcutTo, setShortcutTo] = useState(null);

  useEffect(() => {
    fetchActions();
  }, []);

  return (
    <div>
      <GridContainer spacing={3} style={{ padding: 20, paddingBottom: 100 }}>
        <GridItem xs={12}>
          <Title color="#212121">Użytkownicy</Title>
        </GridItem>
        <GridItem xs={5}>
          <UsersPieChart />
        </GridItem>
        <GridItem xs={7}>
          <UsersBarChart infoTip="Unikalne urządzenia (które wystąpiły w minimum 3 zdarzeniach) dla danego zakresu dat na podstawie wszystkich zdarzeń" />
        </GridItem>
        <GridItem xs={12}>
          <RegisteredUsersTable />
        </GridItem>
        <GridItem xs={12}>
          <Subtitle color="#212121">Użytkownicy w ramach maski</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <UsersLineChartSumCard />
        </GridItem>
        <GridItem xs={12}>
          <UsersLineChart />
        </GridItem>
        <GridItem xs={12}>
          <Subtitle color="#212121">Użytkownicy w ramach medium</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <UserBarByMediumCharts />
        </GridItem>
        {/*<GridItem xs={12}>*/}
        {/*  <Subtitle color="#212121">Użytkownicy ze wskaźnikami</Subtitle>*/}
        {/*</GridItem>*/}
        {/*<GridItem xs={12}>*/}
        {/*  <UsersByMediumCharts />*/}
        {/*</GridItem>*/}
        {/*<GridItem xs={12} style={{ borderBottom: "1px solid #212121", margin: '0 10px' }} />*/}
        {/* <UsersCircularStats />*/}
        <GridItem xs={12}>
          <Subtitle color="#212121">Notyfikacje</Subtitle>
        </GridItem>
        <PushLineChart />
        <GridItem xs={12}>
          <Subtitle color="#212121">Churn</Subtitle>
        </GridItem>
        <ChurnLineChart />
        <GridItem xs={12}>
          <Subtitle color="#212121">Kliknięcia w podpowiedzi pogodowe</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <WeatherHintsTable />
        </GridItem>
        <GridItem xs={12}>
          <Subtitle color="#212121">Ekran zgód</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <ConsentsChart />
        </GridItem>
        <GridItem xs={12}>
          <Subtitle color="#212121">Ankiety - wyświetlenia</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <SurveysViewsChart />
        </GridItem>
        <GridItem xs={12}>
          <Subtitle color="#212121">Ankiety - odpowiedzi</Subtitle>
        </GridItem>
        <GridItem xs={12}>
          <SurveysAnswersTable />
        </GridItem>
        <GridItem
          xs={12}
          style={{ borderBottom: '1px solid #212121', margin: '0 10px' }}
        />
        <GridItem xs={2}>
          <TimeShortcuts
            setTimeTo={setShortcutTo}
            setTimeFrom={setShortcutFrom}
          />
        </GridItem>
        <GridItem xs={10}>
          <UsersTable shortcutFrom={shortcutFrom} shortcutTo={shortcutTo} />
        </GridItem>
        <GridItem xs={12}>
          <PurchaseSumCard />
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default inject('usersStore')(observer(UsersPage));

UsersPage.propTypes = {
  usersStore: PropTypes.shape({
    fetchActions: PropTypes.func,
  }),
};
