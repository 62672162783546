import React from 'react';
import TooltipUI from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

export const InfoTip = ({ text }) => {
  return (
    <TooltipUI style={{ paddingTop: 3, marginLeft: 9 }} title={text}>
      <InfoIcon htmlColor="#212121" />
    </TooltipUI>
  );
};

InfoTip.propTypes = {
  text: PropTypes.string.isRequired,
};
